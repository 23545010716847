import { render, staticRenderFns } from "./form.vue?vue&type=template&id=947683ee&scoped=true"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"
import style0 from "./form.vue?vue&type=style&index=0&id=947683ee&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "947683ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('947683ee')) {
      api.createRecord('947683ee', component.options)
    } else {
      api.reload('947683ee', component.options)
    }
    module.hot.accept("./form.vue?vue&type=template&id=947683ee&scoped=true", function () {
      api.rerender('947683ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hiddenTrouble/riskItemView/checklist/form.vue"
export default component.exports